import { DatoCmsTemplateCaseStudy } from 'graphqlTypes';
import { FC } from 'react';

import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import Image, { ImageData } from 'quarks/Image';
import Link from 'quarks/Link';
import Text from 'quarks/Text';
import type { BasicProps } from 'quarks/interpolations/basic';

interface CaseStudyCardType extends BasicProps, DatoCmsTemplateCaseStudy {
  isDark?: boolean;
}

const BlogSingleCard: FC<CaseStudyCardType> = ({ title, featuredImage, slug, ...props }) => {
  const domain = process.env.GATSBY_DOMAIN || 'screeningSolution';

  return (
    <Link
      href={`/${domain === 'foamHand' ? 'success-stories' : 'case-studies'}/${slug}`}
      display="flex"
      position="relative"
      {...props}
      textDecoration="none"
    >
      <Flex overflowX="hidden" overflowY="hidden" width="100%" height="100%" maxWidth="400px">
        <Flex
          backgroundColor="gray-200"
          width="100%"
          overflowX="hidden"
          overflowY="hidden"
          className="card-image"
          marginBottom={24}
          position="relative"
          borderRadius="8px"
          css={`
            &::before {
              content: '';
              padding-top: 120%;
              display: flex;
            }
          `}
        >
          <Image
            image={featuredImage as ImageData & null}
            alt={featuredImage?.title || ''}
            width="100%"
            height="100%"
            position="absolute"
            top="0"
            left="0"
            objectFit="cover"
            objectPosition="center"
          />
          <Flex
            position="absolute"
            width="100%"
            height="100%"
            left="0"
            top="0"
            flexDirection="column"
            justifyContent="flex-end"
            textColor="common-white"
            gap="24px"
            paddingAll={24}
            css={`
              &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(359.94deg, #025785 0.23%, rgba(2, 106, 162, 0) 99.96%), url(.jpg),
                  linear-gradient(0deg, #003f96, #003f96),
                  radial-gradient(47.96% 47.96% at 85.55% 72.7%, rgba(53, 171, 225, 0.2) 0%, rgba(66, 180, 228, 0) 100%);
                opacity: 0.7;
                z-index: 0;
              }
            `}
          >
            <Text fontWeight="semiBold" textTransform="uppercase" fontSize="textMd" letterSpacing="pos8" zIndex={1}>
              CASE STUDY
            </Text>
            <Heading
              as="h2"
              textStyle="xs"
              zIndex={1}
              md={{
                textStyle: 'sm',
              }}
            >
              {title}
            </Heading>
          </Flex>
        </Flex>
      </Flex>
    </Link>
  );
};

export default BlogSingleCard;
