import { FC } from 'react';

import { media } from 'atoms/breakpoints/breakpoints';
import color from 'atoms/colors/colors';
import { fontSize, lineHeight } from 'atoms/typography/typography';

import Container from 'quarks/Container';
import type { FlexProps } from 'quarks/interpolations/flex';

import useScript from 'utils/useScript';

interface AcitveFormProps extends FlexProps {
  isBackgroundDark?: boolean;
  activeFormId: number;
}

const ContactUsActiveForm: FC<AcitveFormProps> = ({ isBackgroundDark, activeFormId, ...props }) => {
  useScript(`https://s2global.activehosted.com/f/embed.php?id=${activeFormId}`);

  return (
    <Container
      className={`_form_${activeFormId}`}
      {...props}
      css={`
        & {
          form._form[id^='_form_'] {
            max-width: unset;
            margin: 0;
            background-color: transparent !important;
            padding: 0;
            ._form-title {
              color: ${isBackgroundDark ? color.common.white : color.common.black};
              text-transform: uppercase;
              font-weight: 600;
              font-size: ${fontSize.displayXs};
              margin-bottom: 8px;
            }
            ._form-content {
              display: flex;
              flex-wrap: wrap;
              gap: 24px;
              ._form_element {
                ${[media.sm]} {
                  flex: 0 0 100%;
                  &:nth-child(2) {
                    display: none;
                  }
                }
                ._form-label {
                  color: ${isBackgroundDark ? color.common.white : color.common.black};
                  font-size: ${fontSize.textSm};
                  line-height: ${lineHeight.textSm};
                  font-weight: 600;
                }
                input {
                  height: 44px;
                  font-size: ${fontSize.textMd};
                  padding-left: 12px;
                }
                ._form-fieldset ._checkbox-radio {
                  display: flex;
                  align-items: flex-start;
                  gap: 8px;
                  input[type='checkbox'] {
                    height: unset;
                    margin-top: 4px;
                  }
                  label {
                    color: ${isBackgroundDark ? color.common.white : color.common.black};
                  }
                }
              }
              #_form_${activeFormId}_submit {
                background-color: ${color.secondaryTurqoise[400]}!important;
                color: ${color.gray[900]}!important;
                font-family: 'Gilroy', sans-serif;
                font-size: ${fontSize.textMd}!important;
                font-weight: 600;
                padding: 10px 18px !important;
                &:hover {
                  background-color: ${color.secondaryTurqoise[100]}!important;
                }
                &:focus {
                  background-color: ${color.secondaryTurqoise[400]}!important;
                  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #f4ebff;
                }
              }
            }
          }
        }
      `}
    />
  );
};

export default ContactUsActiveForm;
