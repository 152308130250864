import { FC } from 'react';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';

import StructuredTextParser from 'molecules/StructuredTextParser/StructuredTextParser';

import GetStartedCard from 'components/Cards/GetStartedCard';
import TableOfContents from 'components/CaseStudy/TableOfContents';

import { getTableOfContents } from 'utils/functions';

import type { DatoCmsComponentCard, DatoCmsTemplateCaseStudy, Maybe } from 'graphqlTypes';

type CaseStudyDetaillProps = Maybe<DatoCmsTemplateCaseStudy>;

const CaseStudyDetail: FC<CaseStudyDetaillProps> = ({ body, slug, sidebarCard }) => {
  const toc = body && getTableOfContents(body);

  const domain = process.env.GATSBY_DOMAIN || 'screeningSolution';

  return (
    <Flex flexDirection="column-reverse" gap="40px" lg={{ flexDirection: 'row' }}>
      <Flex width="100%" lg={{ width: '66.67%', flex: '0 0 66.67%' }}>
        <Container lg={{ flexGrow: 1, flexBasis: 0 }}>
          {body && <StructuredTextParser text={body} textStyle="lg" textColor="gray-600" />}
        </Container>
      </Flex>
      <Container lg={{ flexGrow: 1, flexBasis: 0, maxWidth: '300px' }}>
        <Container position="sticky" top="120px">
          <Heading as="h5" fontSize="textLg" fontWeight="semiBold" textColor="gray-900" marginBottom={16}>
            In this {domain === 'foamHand' ? 'success Story' : 'case study'}
          </Heading>
          <TableOfContents toc={toc} slug={slug || ''} />
          <GetStartedCard {...(sidebarCard as DatoCmsComponentCard)} />
        </Container>
      </Container>
    </Flex>
  );
};

export default CaseStudyDetail;
