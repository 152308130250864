import { FC } from 'react';

import { media } from 'atoms/breakpoints/breakpoints';
import color from 'atoms/colors/colors';
import { fontSize, lineHeight } from 'atoms/typography/typography';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import type { FlexProps } from 'quarks/interpolations/flex';

import useScript from 'utils/useScript';

import type { DatoCmsComponentForm } from 'graphqlTypes';

interface ComponentFormProps extends FlexProps, Partial<DatoCmsComponentForm> {
  isBackgroundDark?: boolean;
}

const ContactUsFormScreeningInline: FC<ComponentFormProps> = ({ heading, isBackgroundDark }) => {
  useScript('https://s2global.activehosted.com/f/embed.php?id=3&v=540679694759');

  return (
    <Flex flexDirection="column" gap="40px" width="100%">
      <Heading
        as="h4"
        textStyle="sm"
        fontWeight="semiBold"
        marginBottom={16}
        textColor={isBackgroundDark ? 'common-white' : 'gray-900'}
      >
        {heading}
      </Heading>
      <Container
        className="_form_3"
        css={`
          & {
            form._form[id^='_form_'] {
              max-width: unset !important;
              width: 100% !important;
              margin: 0 !important;
              background-color: transparent !important;
              padding: 0;
              ._form-title {
                display: none;
              }
              ._form-content {
                display: flex;
                width: 100%;
                flex-wrap: wrap;
                gap: 16px;
                ._form_element {
                  ${[media.sm]} {
                    flex: 0 0 calc(33.3% - 16px);
                    &:first-child {
                      display: none;
                    }
                    &:nth-child(2) {
                      display: none;
                    }
                  }
                  ._form-label {
                    color: ${color.common.white};
                    font-size: ${fontSize.textSm};
                    line-height: ${lineHeight.textSm};
                    font-weight: 600;
                    margin-top: 16px;
                  }
                  input {
                    height: 44px;
                    font-size: ${fontSize.textMd};
                    padding-left: 12px;
                  }
                }
                #_form_3_submit {
                  background-color: ${color.secondaryTurqoise[400]}!important;
                  color: ${color.gray[900]}!important;
                  font-family: 'Gilroy', sans-serif;
                  font-size: ${fontSize.textMd}!important;
                  font-weight: 600;
                  padding: 10px 18px !important;
                  &:hover {
                    background-color: ${color.secondaryTurqoise[100]}!important;
                  }
                  &:focus {
                    background-color: ${color.secondaryTurqoise[400]}!important;
                    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #f4ebff;
                  }
                }
              }
            }
          }
        `}
      />
    </Flex>
  );
};

export default ContactUsFormScreeningInline;
