import { FC } from 'react';

import { media } from 'atoms/breakpoints/breakpoints';
import color from 'atoms/colors/colors';
import { fontSize, lineHeight } from 'atoms/typography/typography';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import type { FlexProps } from 'quarks/interpolations/flex';

import useScript from 'utils/useScript';

interface ComponentFormProps extends FlexProps {
  isBackgroundDark?: boolean;
}

const ContactUsFormScreening: FC<ComponentFormProps> = () => {
  useScript('https://s2global.activehosted.com/f/embed.php?id=3&v=540679694759');

  return (
    <Flex
      flexDirection="column"
      paddingY={40}
      paddingX={32}
      borderRadius="20px"
      boxShadow="xxl"
      backgroundColor="common-white"
      textColor="gray-900"
      textAlign="center"
      maxWidth="700px"
      marginX="auto"
      position="relative"
      height="max-content"
      border="1px solid"
      borderColor="common-transparent"
      backgroundShorthand="linear-gradient(#ffffff, #ffffff) padding-box, linear-gradient(135deg,#35ABE1 0%, rgba(53, 171, 225, 0) 100%) border-box"
    >
      <Heading as="h3" textStyle="xs" fontWeight="semiBold" textColor="common-black" marginBottom={8}>
        Contact Us
      </Heading>
      <Container marginBottom={24}>Enter your information to contact us</Container>
      <Container
        id="_form_3_wrapper"
        className="_form_3"
        css={`
          & {
            form._form[id^='_form_'] {
              max-width: unset;
              margin: 0;
              background-color: transparent !important;
              padding: 0;
              ._form-title {
                display: none;
              }
              ._form-content {
                ._form_element {
                  ${[media.sm]} {
                    flex: 0 0 calc(33.3% - 16px);
                    &:first-child {
                      flex: 0 0 100%;
                    }
                    &:nth-child(2) {
                      display: none;
                    }
                  }
                  ._form-label {
                    color: ${color.common.black};
                    font-size: ${fontSize.textSm};
                    line-height: ${lineHeight.textSm};
                    font-weight: 600;
                    margin-top: 16px;
                  }
                  input:not([type='checkbox']) {
                    height: 44px;
                    font-size: ${fontSize.textMd};
                    padding-left: 12px;
                  }
                }
                #_form_3_submit {
                  background-color: ${color.secondaryTurqoise[400]}!important;
                  color: ${color.gray[900]}!important;
                  font-family: 'Gilroy', sans-serif;
                  font-size: ${fontSize.textMd}!important;
                  font-weight: 600;
                  padding: 10px 18px !important;
                  &:hover {
                    background-color: ${color.secondaryTurqoise[100]}!important;
                  }
                  &:focus {
                    background-color: ${color.secondaryTurqoise[400]}!important;
                    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #f4ebff;
                  }
                }
              }
            }
            &#_form_3_wrapper form._form._inline-form[id^='_form_']._dark {
              ._form-content {
                ._form_element {
                  ._form-label {
                    color: ${color.common.black}!important;
                  }
                  ._form-fieldset ._checkbox-radio {
                    display: flex;
                    gap: 8px;
                    align-items: flex-start;
                    input[type='checkbox'] {
                      height: unset;
                      margin-top: 6px;
                    }
                    label {
                      color: ${color.common.black}!important;
                    }
                  }
                }
              }
            }
          }
        `}
      />
    </Flex>
  );
};

export default ContactUsFormScreening;
