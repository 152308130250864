import { render as toPlainText } from 'datocms-structured-text-to-plain-text';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import Image, { ImageData } from 'quarks/Image';
import Text from 'quarks/Text';

import Breadcrumbs from 'molecules/Breadcrumbs/Breadcrumbs';

import Author from 'components/Blog/Author';

import type { DatoCmsEntityPerson, DatoCmsTemplateCaseStudy } from 'graphqlTypes';
import type { FC } from 'react';

type HeroProps = DatoCmsTemplateCaseStudy;

const SingleCaseStudyHero: FC<HeroProps> = ({ title, author, excerpt, body, featuredImage, publishDate }) => {
  const caseExcerpt = excerpt || (body && toPlainText(body.value)?.substring(0, 97) + '...');
  const domain = process.env.GATSBY_DOMAIN || 'screeningSolution';
  const breadcrumbs = [
    {
      id: '1',
      link: domain === 'foamHand' ? '/success-stories' : '/case-studies',
      label: domain === 'foamHand' ? 'Success Story' : 'Case Study',
    },
    {
      id: '2',
      link: '',
      label: title,
    },
  ];

  return (
    <Flex paddingTop={128} flexDirection="column">
      <Container marginBottom={48}>
        <Breadcrumbs breadcrumbs={breadcrumbs as any} delimiter="chevron" />
      </Container>
      <Flex gap="30px" alignItems="center">
        <Container
          width="100%"
          sm={{ flex: '0 0 60%', maxWidth: '60%' }}
          xl={{ flex: '0 0 800px', maxWidth: '800px' }}
          borderRadius="8px"
          position="relative"
          overflowX="hidden"
          overflowY="hidden"
        >
          <Image image={featuredImage as ImageData} alt={title || ''} width="100%" />
        </Container>
        <Flex flexDirection="column" textColor="common-white" gap="24px">
          <Text
            fontWeight="semiBold"
            textTransform="uppercase"
            fontSize="textMd"
            letterSpacing="pos8"
            textColor="secondaryTurqoise-400"
            zIndex={1}
          >
            {domain === 'foamHand' ? 'SUCCESS STORY' : 'CASE STUDY'}
          </Text>
          <Heading
            as="h2"
            textStyle="xs"
            zIndex={1}
            letterSpacing="neg2"
            md={{
              textStyle: 'sm',
            }}
            xl={{
              textStyle: 'md',
            }}
          >
            {title}
          </Heading>
          <Container fontSize="textXl" textColor="gray-50" display="none" lg={{ display: 'block' }}>
            {caseExcerpt}
          </Container>
          {author && (
            <Container>
              <Author publishDate={publishDate} {...(author as DatoCmsEntityPerson)} isDark />
            </Container>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SingleCaseStudyHero;
