// Quarks
import { FC } from 'react';

import color from 'atoms/colors/colors';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import type { FlexProps } from 'quarks/interpolations/flex';

import Icon from 'molecules/Icon/Icon';

import ComponentButton from 'components/ComponentButton/ComponentButton';

import type { DatoCmsComponentCard } from 'graphqlTypes';

interface CardProps extends FlexProps, DatoCmsComponentCard {
  isBackgroundDark?: boolean;
}

const GetStartedCard: FC<CardProps> = ({ untitledUiIconName, heading, callToAction, callToActionAlt }) => (
  <Flex
    flexDirection="column"
    borderRadius="16px"
    paddingX={24}
    paddingY={32}
    position="relative"
    textAlign="center"
    overflowX="hidden"
    overflowY="hidden"
    sm={{
      paddingAll: 40,
      textAlign: 'left',
    }}
    lg={{
      paddingX: 24,
      paddingY: 32,
      textAlign: 'center',
    }}
    css={`
      background: linear-gradient(94.96deg, #0D273F 5.15%, #194664 104.64%);
    `}
  >
    {untitledUiIconName && (
      <Container marginBottom={24}>
        <Icon iconName={untitledUiIconName} size={40} color={color.secondaryTurqoise[400]} />
      </Container>
    )}
    <Heading as="h4" textStyle="xs" fontWeight="semiBold" marginBottom={24} textColor="common-white">
      {heading}
    </Heading>
    <Flex flexDirection="column" gap="16px" sm={{ flexDirection: 'row' }} lg={{ flexDirection: 'column' }}>
      {callToAction && <ComponentButton {...callToAction} width="100%" />}
      {callToActionAlt && <ComponentButton {...callToActionAlt} width="100%" />}
    </Flex>
  </Flex>
);

export default GetStartedCard;
