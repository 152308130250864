import { FC, useEffect, useRef, useState } from 'react';

import Flex from 'quarks/Flex';
import Link from 'quarks/Link';

type ToCProps = {
  toc: any[];
  slug: string;
};

const TableOfContents: FC<ToCProps> = ({ toc, slug }) => {
  const [activeAnchor, setActiveAnchor] = useState('');
  const scrollRef = useRef<any>(null);
  useEffect(() => {
    const handleScroll = () => {
      if (scrollRef.current) {
        return false;
      }
      toc.map((item, index) => {
        const nextId = toc[index + 1]?.id;

        const dom = document.getElementById(item.id as string) as HTMLElement;
        if (dom) {
          const rect = dom.getBoundingClientRect();
          if (rect.top < 150) {
            if (nextId) {
              const nextEl = document.getElementById(nextId as string) as HTMLElement;
              if (nextEl) {
                const nextRect = nextEl.getBoundingClientRect();
                if (nextRect.top > 150) {
                  setActiveAnchor(item.id);
                }
              }
            } else {
              setActiveAnchor(item.id);
            }
          }
        }
        return;
      });
    };
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleClick = (id: string) => {
    setActiveAnchor(id);
    scrollRef.current = true;
    setTimeout(() => {
      scrollRef.current = false;
    }, 2000);
  };

  return (
    <Flex paddingAll={16} gap="12px" flexDirection="column" marginBottom={32}>
      {toc &&
        toc.map((item: any, index) => (
          <Link
            href={`/case-studies/${slug}#${item.id}`}
            textDecoration="none"
            key={item.id}
            onClick={() => handleClick(item.id)}
            textColor={item.id === activeAnchor || (activeAnchor === '' && index === 0) ? 'gray-900' : 'gray-500'}
            fontSize="textMd"
            borderLeft="3px solid"
            paddingLeft={12}
            fontWeight={item.id === activeAnchor || (activeAnchor === '' && index === 0) ? 'medium' : 'regular'}
            borderColor={
              item.id === activeAnchor || (activeAnchor === '' && index === 0) ? 'primary-800' : 'common-transparent'
            }
          >
            {item.title}
          </Link>
        ))}
    </Flex>
  );
};

export default TableOfContents;
