import ChevronRight from '@untitled-ui/icons-react/build/cjs/ChevronRight';
import HomeLine from '@untitled-ui/icons-react/build/cjs/HomeLine';
import { FC } from 'react';

import type { GetColorDefinition } from 'atoms/colors/colors';

import Flex from 'quarks/Flex';
import Text from 'quarks/Text';
import type { FlexProps } from 'quarks/interpolations/flex';

import Button from 'molecules/ButtonBase/ButtonBase';

interface BreadcrumbsProps extends FlexProps {
  breadcrumbs: { id: string; link: string; label: string }[];
  delimiter: 'chevron' | 'slash';
}

const Breadcrumbs: FC<BreadcrumbsProps> = ({ breadcrumbs, delimiter = 'chevron', ...props }) => {
  const isChevron = delimiter === 'chevron';

  return (
    <Flex>
      <Flex
        width="fit-content"
        paddingY={12}
        gap={isChevron ? '12px' : '18px'}
        alignItems="center"
        justifyContent="start"
        flexWrap="wrap"
      >
        <Button
          size="small"
          variant="text"
          href=""
          cursor="pointer"
          alignItems="center"
          textColor="secondaryBlue-100"
          {...props}
        >
          <HomeLine viewBox="0 0 24 24" width="16px" height="16px" />
        </Button>
        <Text lineHeight="zero" fontWeight="semiBold" textColor="secondaryBlue-100">
          {isChevron ? <ChevronRight viewBox="0 0 24 24" width="16px" height="16px" /> : <Text>/</Text>}
        </Text>
        {breadcrumbs?.map((menuItem, index) => {
          const isLast = index === breadcrumbs.length - 1;

          const buttonBackgroundColor: GetColorDefinition = 'common-transparent';

          return (
            <>
              <Button
                backgroundColor={buttonBackgroundColor}
                size="small"
                variant="text"
                key={menuItem?.id}
                href={isLast ? '' : menuItem?.link || ''}
                cursor={isLast ? 'default' : 'pointer'}
                {...props}
              >
                <Text textStyle="sm" textColor="secondaryBlue-100" fontWeight="semiBold">
                  {menuItem?.label}
                </Text>
              </Button>
              {!isLast && (
                <Text lineHeight="zero" fontWeight="semiBold" textColor="secondaryBlue-100">
                  {isChevron ? <ChevronRight viewBox="0 0 24 24" width="16px" height="16px" /> : <Text>/</Text>}
                </Text>
              )}
            </>
          );
        })}
      </Flex>
    </Flex>
  );
};

export default Breadcrumbs;

Breadcrumbs.defaultProps = {};
