import { PageProps, graphql } from 'gatsby';
import {
  DatoCmsComponentForm,
  DatoCmsComponentHeading,
  DatoCmsComponentStatisticsPanel,
  DatoCmsSeoField,
  DatoCmsTemplateCaseStudy,
  DatoCmsTemplateCaseStudyConnection,
} from 'graphqlTypes';

import Flex from 'quarks/Flex';

import Section from 'molecules/Section/Section';

import CaseStudyCard from 'components/Cards/CaseStudyCard/CaseStudyCard';
import CaseStudyDetail from 'components/CaseStudy/CaseStudyDetail';
import SingleCaseStudyHero from 'components/CaseStudy/SingleCaseStudyHero';
import ComponentForm from 'components/ComponentForm/ComponentForm';
import ComponentHeading from 'components/Heading/Heading';

import Layout from 'layouts/Layout';

import type { FC } from 'react';

interface CaseStudyDataType {
  caseStudyData: DatoCmsTemplateCaseStudy;
  mostRecentData: DatoCmsTemplateCaseStudyConnection;
  statisticPanelData: DatoCmsComponentStatisticsPanel;
  bottomForm: DatoCmsComponentForm;
  cardHeading: DatoCmsComponentHeading;
}

const CaseStudy: FC<PageProps<CaseStudyDataType>> = ({
  data: { caseStudyData, mostRecentData, bottomForm, cardHeading },
  location,
  pageContext,
}) => {
  console.warn(`${caseStudyData.internalName} is missing seo`); // TODO: Fix pages that have broken SEO items
  const domain = (pageContext as any)?.domain || process.env.GATSBY_DOMAIN || 'screeningSolution';

  return (
    <Layout seo={caseStudyData.seo as DatoCmsSeoField} slug={location.pathname} domain={domain}>
      <Section
        id="case-study-hero"
        paddingTopDesktop="80"
        paddingTopTablet="80"
        paddingTopMobile="80"
        paddingBottomDesktop="56"
        paddingBottomTablet="40"
        paddingBottomMobile="40"
        isOverSection
        backgroundColor="secondaryGrayBlue-900"
        hasSubfix
        nextHasBackground
      >
        <SingleCaseStudyHero {...caseStudyData} />
      </Section>
      <Section
        id={caseStudyData.internalName || caseStudyData.id}
        paddingTopTablet="48"
        paddingTopMobile="48"
        paddingBottomTablet="48"
        paddingBottomMobile="48"
        isOverSection
        backgroundColor="secondaryGrayBlue-25"
      >
        <CaseStudyDetail {...caseStudyData} />
      </Section>
      <Section
        id="recent-blog-list"
        paddingTopDesktop="96"
        paddingTopTablet="72"
        paddingTopMobile="48"
        paddingBottomDesktop="96"
        paddingBottomTablet="72"
        paddingBottomMobile="48"
        isOverSection
        backgroundColor="secondaryGrayBlue-900"
        hasPrefix
      >
        <ComponentHeading {...cardHeading} isSubComponent marginBottom={64} />
        <Flex
          flexWrap="wrap"
          justifyContent="flex-start"
          gap="32px"
          flexDirection="column"
          sm={{ flexDirection: 'row' }}
        >
          {mostRecentData?.edges?.slice(0, 3)?.map((item, idx) => (
            <CaseStudyCard
              key={item.node.id}
              {...item.node}
              width="100%"
              flexGrow={1}
              flexBasis={0}
              isDark
              sm={{
                flex: '0 0 calc(50% - 16px)',
                width: 'calc(50% - 16px)',
                maxWidth: '380px',
                display: idx === 2 ? 'none' : 'flex',
              }}
              lg={{
                flex: '0 0 calc(33.33% - 22px)',
                width: 'calc(33.33% - 22px)',
                display: 'flex',
              }}
            />
          ))}
        </Flex>
      </Section>
      <Section
        id="case-study-box-heading"
        paddingTopTablet="48"
        paddingTopMobile="48"
        paddingBottomTablet="48"
        paddingBottomMobile="48"
        isOverSection
        backgroundColor="globe"
        hasSubfix
        isLastSection
      >
        <ComponentForm {...bottomForm} isBackgroundDark />
      </Section>
    </Layout>
  );
};

export default CaseStudy;

export const caseStudyQuery = graphql`
  query templateCaseStudyQuery($slug: String!, $domain: String!) {
    caseStudyData: datoCmsTemplateCaseStudy(slug: { eq: $slug }, domain: { eq: $domain }) {
      ...datoCmsTemplateCaseStudy
    }
    mostRecentData: allDatoCmsTemplateCaseStudy(filter: { domain: { eq: $domain }, slug: { ne: $slug } }, limit: 3) {
      edges {
        node {
          ...datoCmsTemplateCaseStudyList
        }
      }
    }
    statisticPanelData: datoCmsComponentStatisticsPanel(originalId: { eq: "99104021" }) {
      ...datoCmsComponentStatisticsPanel
    }
    bottomForm: datoCmsComponentForm(originalId: { eq: "121412249" }) {
      ...datoCmsComponentForm
    }
    cardHeading: datoCmsComponentHeading(originalId: { eq: "160086855" }) {
      ...datoCmsComponentHeading
    }
  }
`;
