import { FC } from 'react';

import Flex from 'quarks/Flex';
import Text from 'quarks/Text';

import TextField from 'molecules/TextField/TextField';

import ComponentButton from 'components/ComponentButton/ComponentButton';

const DummyForm: FC = () => (
  <Flex flexDirection="column" gap="24px" textAlign="left" fontSize="textSm">
    <Flex flexDirection="column" gap="24px" sm={{ flexDirection: 'row' }}>
      <Flex flexDirection="column" gap="6px" width="100%" sm={{ flexGrow: 1, flexBasis: 0 }}>
        <Text as="label" fontWeight="semiBold" lineHeight="textSm">
          First name
        </Text>
        <TextField placeholder="Enter first name" type="text" />
      </Flex>
      <Flex flexDirection="column" gap="6px" width="100%" sm={{ flexGrow: 1, flexBasis: 0 }}>
        <Text as="label" fontWeight="semiBold" lineHeight="textSm">
          Last name
        </Text>
        <TextField placeholder="Enter last name" type="text" />
      </Flex>
    </Flex>
    <Flex flexDirection="column" gap="24px" sm={{ flexDirection: 'row' }}>
      <Flex flexDirection="column" gap="6px" width="100%" sm={{ flexGrow: 1, flexBasis: 0 }}>
        <Text as="label" fontWeight="semiBold" lineHeight="textSm">
          Email
        </Text>
        <TextField placeholder="Example@example.com" type="email" />
      </Flex>
      <Flex flexDirection="column" gap="6px" width="100%" sm={{ flexGrow: 1, flexBasis: 0 }}>
        <Text as="label" fontWeight="semiBold" lineHeight="textSm">
          Phone number
        </Text>
        <TextField placeholder="(000) 000-0000" type="text" />
      </Flex>
    </Flex>
    <Flex flexDirection="column" gap="6px" width="100%">
      <Text as="label" fontWeight="semiBold" lineHeight="textSm">
        Country
      </Text>
      <TextField placeholder="Enter country" type="text" />
    </Flex>
    <Flex flexDirection="column" gap="6px" width="100%">
      <Text as="label" fontWeight="semiBold" lineHeight="textSm">
        Name of your company?
      </Text>
      <TextField placeholder="Enter company name" type="text" />
    </Flex>
    <Flex flexDirection="column" gap="6px" width="100%">
      <Text as="label" fontWeight="semiBold" lineHeight="textSm">
        What is your industry
      </Text>
      <TextField placeholder="Enter industry" type="text" />
    </Flex>
    <Flex flexDirection="column" gap="6px" width="100%">
      <Text as="label" fontWeight="semiBold" lineHeight="textSm">
        What is your goal
      </Text>
      <TextField placeholder="Enter your goal" type="text" />
    </Flex>
    <Flex marginTop={8} width="100%">
      <ComponentButton label="Submit" buttonType="fill" width="100%" color="secondary_turqoise_400" />
    </Flex>
  </Flex>
);

export default DummyForm;
