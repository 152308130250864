import { FC } from 'react';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import type { FlexProps } from 'quarks/interpolations/flex';

import ContactUsActiveForm from 'components/ComponentForm/ContactUsActiveForm';
import ContactUsForm from 'components/ComponentForm/ContactUsForm';
import ContactUsFormScreening from 'components/ComponentForm/ContactUsFormScreening';
import ContactUsFormScreeningInline from 'components/ComponentForm/ContactUsFormScreeningInline';
import DummyForm from 'components/ComponentForm/DummyForm';

import type { DatoCmsComponentForm } from 'graphqlTypes';

interface ComponentFormProps extends FlexProps, Partial<DatoCmsComponentForm> {
  isBackgroundDark?: boolean;
}
const ComponentForm: FC<ComponentFormProps> = ({ activeCampaignFormId, ...props }) => {
  switch (activeCampaignFormId) {
    case 'contact':
      return <ContactUsForm {...props} />;
    case 'contact_foam':
      return <ContactUsActiveForm activeFormId={17} {...props} />;
    case 'contact_raggix':
      return <ContactUsActiveForm activeFormId={19} {...props} />;
    case 'contact_s2u':
      return <ContactUsActiveForm activeFormId={21} {...props} />;
    case 'contact_screening_solution_inline':
      return <ContactUsFormScreeningInline {...props} />;
    case 'contact_screening_solution':
      return <ContactUsFormScreening {...props} />;

    default:
      return (
        <Flex
          flexDirection="column"
          paddingY={40}
          paddingX={32}
          borderRadius="20px"
          boxShadow="xxl"
          backgroundColor="common-white"
          textAlign="center"
          maxWidth="700px"
          marginX="auto"
          position="relative"
          height="max-content"
          border="1px solid"
          borderColor="common-transparent"
          textColor="gray-700"
          backgroundShorthand="linear-gradient(#ffffff, #ffffff) padding-box, linear-gradient(135deg,#35ABE1 0%, rgba(53, 171, 225, 0) 100%) border-box"
          {...props}
        >
          <Heading as="h3" textStyle="xs" fontWeight="semiBold" textColor="common-black" marginBottom={8}>
            Contact Us
          </Heading>
          <Container marginBottom={24}>Enter your information to contact us</Container>
          <DummyForm />
        </Flex>
      );
  }
};

export default ComponentForm;
